<template>
	<div style="">

		<el-button type="success" size="small" icon="el-icon-plus" @click="addRow()">{{$t('i18nn_c0246c55b9cac963')}}</el-button>
		<el-button type="danger" size="small" icon="el-icon-delete" @click="delRow(0)">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>

		<div id="hotTable"></div>
	</div>
</template>

<script>
	import Handsontable from 'handsontable';
	import "handsontable/dist/handsontable.full.css";
	import 'handsontable/languages/zh-CN';

	import {
		clearObjectVal
	} from '@/utils/common.js';
	
	import {
		getDicData
	} from '@/axios/common.js';
	
	import {
		settings
	} from '@/utils/hotTableSettings.js';
	
	import {
		columns_config,
		nestedHeadersTop_config
	} from './HotTableTransportInWh.js';

	let columns = columns_config();
	// let DataRowObj = {
	// 	// id: 1,
	// 	"inWhType": null, //this.$t('i18nn_643e08ba53f72f39'),
	// 	"ctnMark": null, //this.$t('i18nn_8a43886ff356ed78'),
	// 	"ctnCount": null, //this.$t('i18nn_53fa7ff1ea383b2f'),
	// 	"goodsSku": null, //"箱内SKU",
	// 	"ctnSkuCount": null, //this.$t('i18nn_85a10d3e5ea0e103'),
	// 	"inWhPallte": null, //this.$t('i18nn_18c1c1a3ea76e9d3'),
	// 	"skuTotal": null, //this.$t('i18nn_bad53577db0da2d3'),
	// 	"ctnL": null, //this.$t('i18nn_70b5c883b227851b'),
	// 	"ctnW": null, //this.$t('i18nn_0a389c3a5e3938d3'),
	// 	"ctnH": null, //this.$t('i18nn_5c31dfdb8f4781ec'),
	// 	"ctnWeight": null, //this.$t('i18nn_91263f8fa1a94da9'),
	// 	"ctnUnit": null, //this.$t('i18nn_b6bf0a07fe26f74f')
	// 	"remark": null, //this.$t('i18nn_15b3627faddccb1d')
	// 	"id": null
	// };

	// let DataRowObj = {};
	// columns.map(item => {
	// 	DataRowObj[item.data] = null;
	// });

	// let dataObject = [DataRowObj];
	// let colHeaders = columns.map(item => {
	// 	return item.name;
	// });

	let option = {
		height: 400,
	}
	let nestedHeadersTop = [];
	let hiddenColumns = {
		columns: [11],
		// indicators: true,
		// exclude hidden columns from copying and pasting
		copyPasteEnabled: false
	};
	let settingData = settings(columns, option, nestedHeadersTop, hiddenColumns);
	let hotTableSettings = settingData.config;
	let DataRowObj = settingData.DataRowObj;



	// let hotTableSettings = {
	// 	data: dataObject,
	// 	className: 'htLeft',
	// 	licenseKey: 'non-commercial-and-evaluation',
	// 	// stretchH: 'all',
	// 	//   width: 805,
	// 	// autoWrapRow: true,
	// 	// height: 487,
	// 	// maxRows: 60,
	// 	manualRowResize: true,
	// 	manualColumnResize: true,
	// 	// rowHeaders: true,
	// 	manualRowMove: true,
	// 	manualColumnMove: true,
	// 	contextMenu: true,
	// 	filters: true,
	// 	dropdownMenu: true,
	// 	copyable: true,
	// 	columns: columns,
	// 	// hiddenColumns: true,
	// 	hiddenColumns: {
	// 		columns: [13],
	// 		// indicators: true,
	// 		// exclude hidden columns from copying and pasting
	// 		copyPasteEnabled: false
	// 	},
	// 	stretchH: 'all',
	// 	width: '100%',
	// 	autoWrapRow: true,
	// 	// minHeight: 100,
	// 	height: 200,
	// 	// height: 'auto',
	// 	// height: '100%',
	// 	colWidths: 100,
	// 	// width: '100%',
	// 	// height: 600,
	// 	rowHeights: 25,
	// 	// rowHeaders: true,
	// 	// colHeaders: true,
	// 	// maxRows: 22,
	// 	rowHeaders: true,
	// 	colHeaders: colHeaders,
	// 	contextMenu: {
	// 		// 单元格右击展示菜单
	// 		items: {
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			// hsep6: '---------',
	// 			// upload_img: {
	// 			//   name: this.$t('i18nn_c5329b923a1a6213'),
	// 			//   // callback: (key, selection, clickEvent) => {
	// 			//   callback: function(key, selection, clickEvent) {
	// 			//     console.log(arguments);
	// 			//     _vue_this.openUpLoad();
	// 			//     _vue_this.selCell = selection[0];
	// 			//     console.log(_vue_this.selCell);
	// 			//   }
	// 			// },
	// 			// hsep6: '---------',
	// 			row_below: {
	// 				name: this.$t('i18nn_382c5bafd600d2bd')
	// 			},
	// 			row_above: {
	// 				name: this.$t('i18nn_8b456c5396713d9a')
	// 			},

	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			// hsep1: '---------',
	// 			// col_left: {
	// 			// 	name: this.$t('i18nn_77054b0e5ea4d30d')
	// 			// },
	// 			// col_right: {
	// 			// 	name: this.$t('i18nn_1b2106ded89310ba')
	// 			// },
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			hsep2: '---------',
	// 			remove_row: {
	// 				name: this.$t('i18nn_f04cf5507ecc5774')
	// 			},
	// 			// remove_col: {
	// 			// 	name: this.$t('i18nn_327aa3849b3d5c68')
	// 			// },
	// 			// "undo", "redo"
	// 			hsep7: '---------',
	// 			separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			undo: {
	// 				name: this.$t('i18nn_b4e5993f97fe04ee')
	// 			},
	// 			redo: {
	// 				name: this.$t('i18nn_1a42987a0df962e9')
	// 			},
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			hsep3: '---------',
	// 			make_read_only: {
	// 				name: this.$t('i18nn_f7f39a6e6d9d26cb')
	// 			},
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			alignment: {
	// 				name: this.$t('i18nn_0607472de4525358')
	// 			},
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			// 'borders':{
	// 			//   name: this.$t('i18nn_ea37e4e88aada736')
	// 			// },
	// 			hsep4: '---------',
	// 			copy: {
	// 				name: this.$t('i18nn_29f67f0644a78bf3')
	// 			},
	// 			cut: {
	// 				name: this.$t('i18nn_6b7fbcd658f0ccdb')
	// 			},
	// 			// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
	// 			hsep5: '---------',
	// 			clear_custom: {
	// 				name: this.$t('i18nn_c420ac9915ca288d'),
	// 				callback: function() {
	// 					this.clear();
	// 				}
	// 			},
	// 		}
	// 	},
	// 	language: 'zh-CN'
	// };

	export default {
		components: {

		},
		props: {
			// selectDicOption:{}
		},
		watch: {

		},
		data() {
			return {
				hotTable: {},
				// hiddenColumn:[12]
				// plugin: null,
				selectOption: {
					wh_size_unit: [],
					wh_transfer_Inwh_type: [],
					wh_transfer_arrive_way: []
				},
			};
		},
		created() {
			console.log('created');
			this.$nextTick(() => {
				this.initHotTable();
			});
		},
		mounted() {
			this.$nextTick(() => {
				// this.getDicData();
				//数据字典
				getDicData(['wh_transfer_Inwh_type', 'wh_transfer_arrive_way',
						'wh_size_unit'],
					(data)=>{
						this.selectOption.wh_size_unit = data['wh_size_unit'];
						this.selectOption.wh_transfer_Inwh_type = data['wh_transfer_Inwh_type'];
						this.selectOption.wh_transfer_arrive_way = data['wh_transfer_arrive_way'];
						this.updateSettingsColumns();
				});
			});
		},
		methods: {
			//初始化HotTable
			initHotTable() {
				// this.$nextTick(() => {
				let hotElement = document.querySelector('#hotTable');
				console.log('hotElement', hotElement);

				let hot = new Handsontable(hotElement, hotTableSettings);
				this.hotTable = hot;
				// this.plugin = this.hotTable.getPlugin('hiddenColumns');
				console.log('this.hotTable', this.hotTable);
				this.clear();
				// 				this.hotTable.loadData([{
				// 	// id: 1,
				// 	"inWhType": "1", //this.$t('i18nn_643e08ba53f72f39'),
				// 	"ctnMark": "asa111", //this.$t('i18nn_8a43886ff356ed78'),
				// 	"ctnCount": 123, //this.$t('i18nn_53fa7ff1ea383b2f'),
				// 	"goodsSku": null, //"箱内SKU",
				// 	"ctnSkuCount": null, //this.$t('i18nn_85a10d3e5ea0e103'),
				// 	"inWhPallte": null, //this.$t('i18nn_18c1c1a3ea76e9d3'),
				// 	"skuTotal": null, //this.$t('i18nn_bad53577db0da2d3'),
				// 	"ctnL": null, //this.$t('i18nn_70b5c883b227851b'),
				// 	"ctnW": null, //this.$t('i18nn_0a389c3a5e3938d3'),
				// 	"ctnH": null, //this.$t('i18nn_5c31dfdb8f4781ec'),
				// 	"ctnWeight": null, //this.$t('i18nn_91263f8fa1a94da9'),
				// 	"ctnUnit": null, //this.$t('i18nn_b6bf0a07fe26f74f')
				// }]);
				// });
			},
			//更新列表配置
			updateSettingsColumns() {
				// let select_0 = this.selectOption.wh_transfer_Inwh_type.map(item => {
				// 	return item.codeText;
				// });
				let select_1 = this.selectOption.wh_size_unit.map(item => {
					return item.codeText;
				});
				// hotTableSettings.columns[0].selectOptions = select_0;
				// hotTableSettings.columns[8].selectOptions = select_1;

				// hotTableSettings.columns[0].source = select_0;
				hotTableSettings.columns[7].source = select_1;

				this.hotTable.updateSettings({
					columns: hotTableSettings.columns
				});
			},

			addRow() {
				this.$nextTick(() => {
					// this.hotTable.alter(action,index,amount,source,keepEmptyRows);
					// action:有4个值insert_row，insert_col，remove_row，remove_col
					// index：行索引，当存在时在索引处插入或删除，不存在时在当前行后插入
					// amount：默认值为1
					// source：行或列对象
					// keepEmptyRows：true/false，当值为true时，空行不被删除。
					this.hotTable.alter('insert_row', 10000, 1, DataRowObj, true);
				})
			},
			delRow(index) {
				this.$nextTick(() => {
					console.log('getSelected',this.hotTable.getSelected());
					console.log('getSelectedRange',this.hotTable.getSelectedRange());
					console.log('getValue',this.hotTable.getValue());
					// getValue()
					// this.hotTable.removeRow(index)
					// this.hotTable.alter('remove_row', 0, 1, {}, true);
					this.$alert(`
					请<strong style="color:#E6A23C;">鼠标右键</strong>单元格,操作${this.$t('i18nn_f04cf5507ecc5774')};<br />
					如需更多操作,查看上方<strong style="color:#E6A23C;">操作提示</strong>.`,
						this.$t('tips.tipsTitle'), {
							type: 'success',
							dangerouslyUseHTMLString: true,
						});
				})
			},

			hideColumn(arr) {
				hiddenColumns.columns = hiddenColumns.columns.concat(arr);
				// return hiddenColumns.columns;
			},
			showColumn(arr) {
				hiddenColumns.columns = hiddenColumns.columns.filter(item => {
					return !arr.includes(item);
				})
				// return hiddenColumns.columns;
			},
			//表格SKU列关联隐藏
			hideSkuColumn() {
				console.log('hideSkuColumn');
				this.hideColumn([8, 9, 10]);
				console.log(hiddenColumns.columns);
				this.hotTable.updateSettings({
					hiddenColumns: {
						// columns: [9, 10, 11, 12],
						columns: hiddenColumns.columns,
						// indicators: true,
						// exclude hidden columns from copying and pasting
						copyPasteEnabled: false
					}
				});
				// this.plugin.hideColumn(9, 10, 11, 13);
			},
			//表格SKU列关联显示
			showSkuColumn() {
				console.log('showSkuColumn');
				this.showColumn([8, 9, 10]);
				console.log(hiddenColumns.columns);
				this.hotTable.updateSettings({
					hiddenColumns: {
						// columns: [12],
						columns: hiddenColumns.columns,
						// indicators: true,
						// exclude hidden columns from copying and pasting
						copyPasteEnabled: false
					}
				});
				// this.plugin.showColumn(13);
			},
			//快进快出列关联隐藏
			hideColumn_TrOpTypeFast() {
				console.log('hideColumn_TrOpTypeFast');
				this.hideColumn([3,4, 5, 6, 7]);
				this.hotTable.updateSettings({
					hiddenColumns: {
						columns: hiddenColumns.columns,
						// indicators: true,
						// exclude hidden columns from copying and pasting
						copyPasteEnabled: false
					}
				});
				// this.plugin.hideColumn(9, 10, 11, 13);
			},
			//快进快出列关联显示
			showColumn_TrOpTypeFast() {
				console.log('showColumn_TrOpTypeFast');
				this.showColumn([3,4, 5, 6, 7]);
				try {
					this.hotTable.updateSettings({
						hiddenColumns: {
							columns: hiddenColumns.columns,
							// indicators: true,
							// exclude hidden columns from copying and pasting
							copyPasteEnabled: false
						}
					});
				} catch(e){
					console.log(e);
				}
				
				// this.plugin.showColumn(13);
			},

			//数据字典格式化
			formatterObjData(objArr) {
				let Arr = [];
				console.log('this.selectOption', this.selectOption);
				objArr.map(item => {
					// let inWhType = item.inWhType;
					// let inWhTypeObj = 
					// this.selectOption.wh_transfer_Inwh_type.map(item2=>{
					// 	// return item2.code == item.inWhType;
					// 	if(item2.code == item.inWhType){
					// 		item.inWhType = item2.codeText
					// 	}
					// });
					item.inWhType = item.inWhTypeName;
					// let ctnUnit = item.ctnUnit;
					// let ctnUnitObj = 
					// this.selectOption.wh_size_unit.map(item2=>{
					// 	// return item2.code == item.ctnUnit;
					// 	if(item2.code == item.ctnUnit){
					// 		item.ctnUnit = item2.codeText
					// 	}
					// });
					item.ctnUnit = item.ctnUnitName;
					// if(inWhTypeObj && inWhTypeObj.codeText){
					// 	item.inWhType =inWhTypeObj.codeText;
					// }
					// if(ctnUnitObj && ctnUnitObj.codeText){
					// 	item.ctnUnit =ctnUnitObj.codeText;
					// }
					Arr.push(item);
					// 	Arr.push([
					// 		inWhTypeObj && inWhTypeObj.codeText?inWhTypeObj.codeText:inWhType, //this.$t('i18nn_643e08ba53f72f39'),
					// 		item.ctnMark, //this.$t('i18nn_8a43886ff356ed78'),
					// 		item.ctnCount, //this.$t('i18nn_53fa7ff1ea383b2f'),
					// 		item.goodsSku, //"箱内SKU",
					// 		item.ctnSkuCount, //this.$t('i18nn_85a10d3e5ea0e103'),
					// 		item.inWhPallte, //this.$t('i18nn_18c1c1a3ea76e9d3'),
					// 		item.skuTotal, //this.$t('i18nn_bad53577db0da2d3'),
					// 		item.ctnL, //this.$t('i18nn_70b5c883b227851b'),
					// 		item.ctnW, //this.$t('i18nn_0a389c3a5e3938d3'),
					// 		item.ctnH, //this.$t('i18nn_5c31dfdb8f4781ec'),
					// 		item.ctnWeight, //this.$t('i18nn_91263f8fa1a94da9'),
					// 		ctnUnitObj && ctnUnitObj.codeText?ctnUnitObj.codeText:ctnUnit, //this.$t('i18nn_b6bf0a07fe26f74f')
					// 		item.id?item.id:null,
					// 	]);
				});
				return Arr;
				// return objArr;
			},
			//初始化和加载数据
			loadData(objArr) {
				let data = this.formatterObjData(objArr);
				console.log('loadData', data);
				this.hotTable.loadData(data);
			},
			//隐藏的列为null
			isHideColumns(hideIndex) {
				return hiddenColumns.columns.includes(hideIndex);
			},
			//数组转对象
			formatterArrToObj(arr) {
				let reArr = [];
				// if(!this.selectOption.wh_transfer_Inwh_type || this.selectOption.wh_transfer_Inwh_type.length<=0){
				// 	this.selectOption = this.selectDicOption;
				// }
				arr.map(item => {
					// let inWhType = item[0] ? item[0] : '';
					// let inWhTypeObj = this.selectOption.wh_transfer_Inwh_type.find(item2 => {
					// 	return item2.codeText == inWhType.replace(/(^\s*)|(\s*$)/g, '');
					// });
					let ctnUnit = item[7] ? item[7] : '';
					let ctnUnitObj = this.selectOption.wh_size_unit.find(item2 => {
						return item2.codeText == ctnUnit.replace(/(^\s*)|(\s*$)/g, '');
					});

					reArr.push({
						// "inWhType": inWhTypeObj && inWhTypeObj.code ? inWhTypeObj.code : inWhType, //this.$t('i18nn_643e08ba53f72f39'),
						"ctnMark": item[0], //this.$t('i18nn_8a43886ff356ed78'),
						"ctnCount": item[1], //this.$t('i18nn_53fa7ff1ea383b2f'),
						"inWhPallte": item[2], //this.$t('i18nn_18c1c1a3ea76e9d3'),

						"ctnL": this.isHideColumns(3) ? null : item[3], //this.$t('i18nn_70b5c883b227851b'),
						"ctnW": this.isHideColumns(4) ? null : item[4], //this.$t('i18nn_0a389c3a5e3938d3'),
						"ctnH": this.isHideColumns(5) ? null : item[5], //this.$t('i18nn_5c31dfdb8f4781ec'),
						"ctnWeight": this.isHideColumns(6) ? null : item[6], //this.$t('i18nn_91263f8fa1a94da9'),
						"ctnUnit": this.isHideColumns(7) ? null : (ctnUnitObj && ctnUnitObj.code ? ctnUnitObj.code :
							ctnUnit), //this.$t('i18nn_b6bf0a07fe26f74f')
						"goodsSku": this.isHideColumns(8) ? null : item[8], //"箱内SKU",
						"ctnSkuCount": this.isHideColumns(9) ? null : item[9], //this.$t('i18nn_85a10d3e5ea0e103'),
						"skuTotal": this.isHideColumns(10) ? null : item[10], //this.$t('i18nn_bad53577db0da2d3'),
						// "remark":item[12],//备注
						id: item[11] ? item[11]: null
					});
				});
				return reArr;
			},
			//获取数据
			getData() {
				let hotTableData = this.hotTable.getData();
				console.log(hotTableData);
				//过滤前三列不为空的数据
				hotTableData = hotTableData.filter((item)=>{
					return !!item[0] &&!!item[1];
				});
				let data = this.formatterArrToObj(hotTableData);
				console.log(data);
				return data;
			},
			//清空数据
			clear() {
				this.$nextTick(() => {
					console.log('clear', DataRowObj);
					// this.loadData([DataRowObj])
					let new_DataRowObj = clearObjectVal(DataRowObj);
					this.hotTable.loadData([new_DataRowObj]);
				});
				// this.hotTable.table.clear();

			},
			//查询数据字典
			// getDicData() {
			// 	//如果有传值
			// 	// if(this.selectDicOption.wh_transfer_Inwh_type && this.selectDicOption.wh_transfer_Inwh_type.length<=0){
			// 	// 	this.selectOption = this.selectDicOption;
			// 	// 	this.updateSettingsColumns();
			// 	// 	return;
			// 	// } else {
			// 	// 	console.log("selectDicOption无值");
			// 	// }
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_transfer_Inwh_type', 'wh_transfer_arrive_way',
			// 			'wh_size_unit'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_transfer_Inwh_type = data.data['wh_transfer_Inwh_type'];
			// 				this.selectOption.wh_transfer_arrive_way = data.data['wh_transfer_arrive_way'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.queryErrorFailed');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 			this.updateSettingsColumns();
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.queryRequestFailed'));
			// 			// this.initHotTable();
			// 		});
			// }
		}
	}
</script>

<style>
	/* .htSelectEditor {
		display: inline-block !important;
	} */
	.handsontable .htAutocompleteArrow {
		color: #999;
	}
</style>