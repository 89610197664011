<template>
	<div class="mainTem">
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('i18nn_ca5a01a5adf20fe7')}}</el-button>

						<span class="tct_tit" v-if="editId">{{$t('i18nn_6267f3aedf895209')}}</span>
						<span class="tct_tit" v-else>{{$t('i18nn_24c8f46012a25c89')}}</span>
						<span>{{$t('i18nn_56b48b3b6de43bde')}}</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"> -->
					<!-- </el-button> -->
				</el-col>
			</el-row>
		</div>
		<!-- <el-form ref="form" :disabled="!hasEdit" :rules="formBaseRules" :model="formBase" label-width="150px" style="width: 1100px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')"> -->

		<!-- <el-tabs type="border-card"> -->
		<!-- <el-tab-pane :label="$t('i18nn_3374a2514ef7dc5d')"> -->
		<!-- <el-card> -->
		<el-form ref="form" inline :disabled="isOnlyView" :rules="formRules" :model="form" inline label-width="150px"
			style="width: 100%;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">

			<!-- form: {
				"whNo": null, //"仓库",
				"inWhType": null, //"装箱类型",
				"arriveWhWay": null, //"送仓方式",
				"isShowSku": null, //"是否展示箱子内产品",
				"arriveWhDate": null, //"到仓时间",
				"inWhPallte": null, //"入库托盘数",
				"relationNo": null, //"关联编号",
				"fileList": [{
					"type": null, //"90",
					"url": null, //"url",
					"fileName": ""
				}],
				"recoreList": [{
					"ctnMark": null, //"箱子标识",
					"ctnCount": null, //"箱子数",
					"goodsSku": null, //"箱内SKU",
					"ctnSkuCount": null, //"每箱件数",
					"inWhPallte": null, //"托盘数",
					"skuTotal": null, //"总件数",
					"ctnL": null, //"箱子长",
					"ctnW": null, //"箱子宽",
					"ctnH": null, //"箱子高",
					"ctnWeight": null, //"箱子重",
					"ctnUnit": null, //"单位"
				}]
			}, -->

			<el-card>
				<!-- <div slot="header" class="">
					<span>{{ $t('Storage.packing_list.basic_information') }}</span>
				</div> -->
				<div>
					<el-form-item :label="$t('i18nn_8af1fd820046d3c1')" prop="" required>
						<whNoSelect ref="whNoSelect" @changeData="changWhNo" size="''"></whNoSelect>
					</el-form-item>
					<!-- <el-form-item :label="$t('i18nn_643e08ba53f72f39')" prop="">
						<el-select filterable clearable v-model="form.inWhType"
							:placeholder="$t('FormMsg.Please_select')" style="width: 150px;">
							<el-option v-for="item in selectOption.wh_transfer_Inwh_type" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item :label="$t('i18nn_1db6434a8c2ba647')" prop="" required>
						<el-select filterable clearable v-model="form.arriveWhWay"
							:placeholder="$t('FormMsg.Please_select')" style="width: 150px;">
							<el-option v-for="item in selectOption.wh_transfer_arrive_way" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</el-form-item>
					
					
					
					<!-- <el-form-item :label="$t('i18nn_696eca4149742942')" prop="">
						<el-radio-group v-model="form.isShowSku">
							<el-radio :label="'0'">{{$t('i18nn_e76d885ae1c74d4f')}}</el-radio>
							<el-radio :label="'1'">{{$t('i18nn_02ccd2cf723f9272')}}</el-radio>
						</el-radio-group>
					</el-form-item> -->

					<el-form-item :label="$t('i18nn_9be246e60da2a0a2')" prop="" required>
						<el-date-picker v-model="form.arriveWhDate" type="date" value-format="yyyy-MM-dd"
							:placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
					</el-form-item>

					<!-- <el-form-item :label="$t('i18nn_33a85575edeaacaa')" prop="">
						<el-input-number size="" v-model="form.inWhPallte" maxlength="">
						</el-input-number>
					</el-form-item> -->

					<el-form-item prop="" required>
						<template slot="label">
							<span>
								<span>{{$t('i18nn_3d3bc466f13154d4')}}</span>/<span>{{$t('i18nn_daa60c502de1462f')}}</span>
							</span>
						</template>
						<!-- <el-input type="text" v-model="form.relationNo"
							:placeholder="$t('FormMsg.Please_Enter')"></el-input> -->
						<el-input type="textarea" :rows="3" :placeholder="$t('i18nn_3107835382623271')"
							v-model="form.relationNo" :maxlength="1000" show-word-limit style="width: 460px;">
						</el-input>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
						<el-input type="textarea" :rows="3" placeholder="" v-model="form.remark" :maxlength="2000" show-word-limit style="width: 460px;">
						</el-input>
					</el-form-item>
					
					<!-- <div>
						<el-form-item :label="$t('i18nn_e1e2b21c7b4b951f')" prop="" required>
								<el-radio-group v-model="form.trOpType" @change="changeTrOpType">
									<el-radio v-for="item in selectOption.biz_wh_in_tr_type" :key="item.code" :label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio>
								</el-radio-group>
						</el-form-item>
					</div> -->
					
				</div>
			</el-card>
			
			<!-- <el-card style="margin-top: 1px;" v-if="'10'==form.trOpType">
				<el-alert type="success" show-icon>
					<div slot="title">{{$t('i18nn_daaaeb1b7b22b126')}}</div>
					<div>
						<div>{{$t('i18nn_2928fee14cabb006')}}<strong>{{$t('i18nn_cd85c52ecc16ffe2')}}</strong>-<strong>{{$t('i18nn_4672c6113ffede32')}}</strong></div>
					</div>
				</el-alert>
			</el-card> -->
			
			<!-- <el-card style="margin-top: 1px;" v-if="'10'==form.trOpType">
				<div>
					<div>
						<el-form-item prop="" required>
							<template slot="label">
								<span>
									<span>{{$t('i18nn_e1d76a34da86da92')}}</span><span>FBA</span>
								</span>
							</template>
							<el-radio-group v-model="form.isFba">
								<el-radio :label="'0'">{{$t('i18nn_e76d885ae1c74d4f')}}</el-radio>
								<el-radio :label="'1'">{{$t('i18nn_02ccd2cf723f9272')}}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item prop="" v-if="'1'==form.isFba" required>
							<template slot="label">
								<span>
									FBA<span>{{$t('i18nn_5acbec83efb27445')}}</span>
								</span>
							</template>
							
							<el-input size="" clearable v-model="form.fbaAddrCode">
								<el-button slot="append" type="primary" icon="el-icon-search" @click="searchFbaAddress()">{{$t('i18nn_ffe3865fea625e35')}}</el-button>
							</el-input>
							 
						</el-form-item>
					</div>
					<div>
						<el-form-item :label="$t('i18nn_e520453b3f22ad66')" prop="">
							<transferAddressSel ref="transferAddressSel" @changeData="changeTransferAddress">
							</transferAddressSel>
							<span style="padding-left: 10px;">
								<el-checkbox v-model="isSaveAddress">{{$t('i18nn_4ce9979bfb6576d9')}}</el-checkbox>
							</span>
						</el-form-item>
						<el-form-item :label="$t('i18nn_59764ba919e3d70d')" prop="" required v-if="isSaveAddress">
							<el-input type="text" clearable v-model="addressName"
								:placeholder="$t('FormMsg.Please_Enter')"></el-input>
						</el-form-item>
						<div style="border: 1px dashed #e5e5e5; padding: 10px 0;">
							<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="" :required="isSaveAddress">
								<el-input clearable type="textarea" :rows="2" v-model="form.addr"
									:placeholder="$t('FormMsg.Please_Enter')" style="width: 220px;"></el-input>
							</el-form-item>
							<el-form-item :label="$t('i18nn_5a9fb0915ecea987')" prop="">
								<el-input type="text" clearable v-model="form.phone" :placeholder="$t('FormMsg.Please_Enter')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" :required="isSaveAddress">
								<el-input type="text" clearable v-model="form.city" :placeholder="$t('FormMsg.Please_Enter')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('i18nn_898a2833fbeaf9e4')" prop="" :required="isSaveAddress">
								<el-input type="text" clearable v-model="form.state" :placeholder="$t('FormMsg.Please_Enter')">
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" :required="isSaveAddress">
								<el-input type="text" clearable v-model="form.country"
									:placeholder="$t('FormMsg.Please_Enter')"></el-input>
							</el-form-item>
							<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" :required="isSaveAddress">
								<el-input type="text" clearable v-model="form.postalCode"
									:placeholder="$t('FormMsg.Please_Enter')"></el-input>
							</el-form-item>
						</div>
						
					</div>
				</div>
			</el-card> -->

			<el-card style="margin-top: 10px;">
				<div slot="header" class="">
					<h3>
						<span>{{$t('i18nn_266578312b060df9')}}</span>
						<!-- <span><TipsActionHotTable :type="2"></TipsActionHotTable></span> -->
						<span style="padding-left: 50px;">
							<!-- <el-checkbox v-model="isShowSkuCheck">{{$t('i18nn_696eca4149742942')}}</el-checkbox> -->
							<span style="padding: 0 10px;">{{$t('i18nn_696eca4149742942')}}</span>
							<el-switch v-model="form.isShowSku" :active-value="'1'" :inactive-value="'0'"
								active-text="YES" inactive-text="NO" @change="changeIsShowSku">
							</el-switch>

						</span>
					</h3>

				</div>
				<div>
					<!-- <el-alert
					    title="可批量复制Excel中数据填入;可鼠标右键表格编辑;保存时请删除空行;"
					    type="success">
					</el-alert> -->

					<el-alert :title="$t('i18nn_41413cddfa61538c')" type="success" description="">
						<div style="font-size: 14px;line-height: 150%;" >
							<div>
								<span>{{$t('tips.long_TipsActionHotTable_1_1')}}</span>
								<!-- <span v-else>{{$t('tips.long_TipsActionHotTable_1')}}</span> -->
							</div>
							<div>{{$t('tips.long_TipsActionHotTable_2')}}</div>
							<div>{{$t('tips.long_TipsActionHotTable_3')}}</div>
						</div>
					</el-alert>
					
					<div style="">
						<!-- 下载模板 -->
						<!-- <el-link
							:href="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/newModel/tranferInMondel.xlsx?random=' + Math.random()"
							target="_blank" type="primary" icon="el-icon-download">
							{{ $t('module.upload.Download_template') }}
						</el-link> -->
						
					</div>
					<HotTableTransportInWh ref="HotTableTransport" :selectDicOption="selectOption">
					</HotTableTransportInWh>
				</div>
			</el-card>

			<!-- <el-card style="margin-bottom: 10px;">
				<div slot="header" class=""><span>{{$t('i18nn_266578312b060df9')}}</span></div>
				<div>
					<el-button type="primary" icon="el-icon-plus" size="small" @click="addDet()">{{$t('i18nn_2063a37475d39272')}}</el-button>
					<span style="padding-left: 50px;">
						<el-checkbox v-model="isShowSkuCheck">{{$t('i18nn_696eca4149742942')}}</el-checkbox>
					</span>
					<div style="margin-top: 10px;">
						<el-table border :data="form.recoreList" size="small" style="width: 100%;">
							<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
								:label="$t('Storage.tableColumn.no')"></el-table-column>
							<el-table-column prop="ctnMark" :label="$t('i18nn_643e08ba53f72f39')" width="120">
								<template slot-scope="scope">
									<el-select size="small" filterable clearable v-model="scope.row.inWhType"
										:placeholder="$t('FormMsg.Please_select')">
										<el-option v-for="item in selectOption.wh_transfer_Inwh_type" :key="item.code"
											:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="ctnMark" :label="$t('i18nn_8a43886ff356ed78')" width="200">
								<template slot-scope="scope">
									<el-input size="small" clearable v-model="scope.row.ctnMark" maxlength=""></el-input>
								</template>
							</el-table-column>
							<el-table-column prop="ctnCount" :label="$t('i18nn_53fa7ff1ea383b2f')" width="160">
								<template slot-scope="scope">
									<el-input-number size="small" v-model="scope.row.ctnCount" maxlength="">
									</el-input-number>
								</template>
							</el-table-column>
							<el-table-column prop="goodsSku" label="箱内SKU" width="200">
								<template slot-scope="scope">
									<div>
										<el-button size="mini" type="primary" icon="el-icon-link" @click="openSelData($event, scope.row, scope.$index)">选择SKU</el-button>
										<el-input size="small" clearable v-model="scope.row.goodsSku" maxlength=""></el-input>
									</div>
								</template>
							</el-table-column>

							<el-table-column prop="ctnSkuCount" :label="$t('i18nn_85a10d3e5ea0e103')" width="160">
								<template slot-scope="scope">
									<el-input-number size="small" v-model="scope.row.ctnSkuCount" maxlength="">
									</el-input-number>
								</template>
							</el-table-column>

							<el-table-column prop="inWhPallte" :label="$t('i18nn_18c1c1a3ea76e9d3')" width="160">
								<template slot-scope="scope">
									<el-input-number size="small" v-model="scope.row.inWhPallte" maxlength="">
									</el-input-number>
								</template>
							</el-table-column>

							<el-table-column prop="skuTotal" :label="$t('i18nn_bad53577db0da2d3')" width="160">
								<template slot-scope="scope">
									<el-input-number size="small" v-model="scope.row.skuTotal" maxlength="">
									</el-input-number>
								</template>
							</el-table-column>

							<el-table-column prop="ctnUnit" :label="$t('i18nn_b6bf0a07fe26f74f')" width="120">
								<template slot-scope="scope">
									<el-select filterable clearable size="small" v-model="scope.row.ctnUnit"
										:placeholder="$t('FormMsg.Please_select')">
										<el-option v-for="item in selectOption.wh_size_unit" :key="item.code"
											:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
									</el-select>
								</template>
							</el-table-column>

							<el-table-column prop="ctnL" label="单箱尺寸(L*W*H)" width="180">
								<template slot-scope="scope">
									<el-input-number size="small" v-model="scope.row.ctnL" maxlength="">
									</el-input-number>L*<br />
									<el-input-number size="small" v-model="scope.row.ctnW" maxlength="">
									</el-input-number>W*<br />
									<el-input-number size="small" v-model="scope.row.ctnH" maxlength="">
									</el-input-number>H
								</template>
							</el-table-column>

							<el-table-column prop="ctnWeight" :label="$t('i18nn_9e168db87b716866')" width="160">
								<template slot-scope="scope">
									<el-input-number size="small" clearable v-model="scope.row.ctnWeight" maxlength="">
									</el-input-number>
								</template>
							</el-table-column>

							<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')" width="200">
								<template slot-scope="scope">
									<el-input type="textarea" size="small" clearable v-model="scope.row.remark" maxlength="">
									</el-input>
								</template>
							</el-table-column>

							<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right"
								align="left">
								<template slot-scope="scope">
									<div>
										<el-button @click="delDet($event, scope.row,scope.$index)" type="danger"
											size="small" icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>

				</div>
			</el-card> -->

			<el-card style="margin-top: 10px;" :body-style="{padding:'20px'}">
				<div slot="header" class="">
					<span>{{$t('i18nn_7fba76c83eec6d09')}}</span>
				</div>
				<div>
					<div>
						<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="">
							<whFileUploadInner ref="whFileUploadInner" :openTime="FileUploadOpenTime"
								:relationId="fileRelationId" @success="FileUploadSuccess">
							</whFileUploadInner>
						</el-form-item>
					</div>
					
					<!-- <el-button @click="openUploadFile($event)" type="success" size="" icon="el-icon-upload2">
						{{$t('FormMsg.upload_attachment')}}
					</el-button> -->

					<!-- <div>
						<ul>
							<li v-for="(item,index) in form.fileList" :key="index">
								<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
									:fit="'contain'" :src="item.url" :preview-src-list="[item.url]">
								</el-image>
								<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
							</li>
						</ul>
					</div> -->
					<!-- <div>
						<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
							<el-input type="textarea" :rows="3" placeholder="" v-model="form.remark" style="width: 500px;">
							</el-input>
						</el-form-item>
					</div> -->
					

				</div>
			</el-card>
		</el-form>

		<div class="submit-footer" v-if="!isOnlyView">
			<el-button type="warning" icon="el-icon-tickets" @click="submitFormAction()" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
		</div>

		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto"
					height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!--选择sku-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogSelVisible" width="1200px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="$t('FormMsg.Select')" append-to-body :visible.sync="dialogSelVisible" :direction="'rtl'" size="1200px">
			<div style="">
				<WSkuInfo :isSel="true" :status="''" @selectRow="selWhGoodsData"></WSkuInfo>
			</div>
			<!-- <div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSelVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div> -->
		</el-drawer>
		<!-- </el-dialog> -->
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import TransshipmentImport from '@/components/StorageCenter/workOrder/workOrderTem/TransshipmentImport.vue';
	import TipsActionHotTable from '@/components/StorageCenter/workOrder/TipsActionHotTable.vue';
	
	import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
	
	import transferAddressSel from '@/components/StorageCenter/components/transferAddressSel.vue';
	
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	import whFileUploadInner from '@/components/StorageCenter/components/whFileUploadInner.vue';
	import HotTableTransportInWh from '@/components/StorageCenter/Transport/HotTableTransportInWh.vue';

	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		// 	// mobile:"",
		// 	editId: {
		// 		// default: function() {
		// 		//   return false;
		// 		// },
		// 		// type: Boolean
		// 	},
		// 	openTime: {
		// 		// default: function() {
		// 		//   return '';
		// 		// },
		// 		// type: String
		// 	}
		// },
		components: {
			TipsActionHotTable,
			WSkuInfo,
			// HyElWhUpLoad,
			// HyQuillEditor,
			// TransshipmentImport，
			transferAddressSel,
			whNoSelect,
			// whFileUpload,
			HotTableTransportInWh,
			whFileUploadInner
		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				// dialogFormMsg: '',
				editId: '',
				//导入excel
				// dialogUploadVisible: false,
				loadingExcel: false,
				fileExcel: '',
				// excelData: [],
				excelFileName: '',

				fileRelationId: '',
				FileUploadOpenTime: '',

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				//打开SKU选择框
				dialogSelVisible: false,
				openRowData: {},
				openRowIndex: {},

				loading: false,

				loading_det: false,

				// form: {
				// 	sendNo: '', //this.$t('i18nn_a2df50399ce1bf10'),
				// 	// recevieName: '', //this.$t('i18nn_e44e9a291d80c593'),
				// 	whNo: '', //this.$t('i18nn_5acbec83efb27445'),
				// 	// recevieMobile: '', //this.$t('i18nn_b29bf013aaec9ce6'),
				// 	// postCode: '', //this.$t('i18nn_c4913ab43009b365'),
				// 	// country: '', //this.$t('i18nn_b166e4e8fe9513fa'),
				// 	// province: '', //"省/州",
				// 	// city: '', //this.$t('i18nn_e05b5d049b64b040'),
				// 	// address1: '', //this.$t('i18nn_8db45b615b72b7a8'),
				// 	carTmsType: '', //this.$t('i18nn_5165f3ee9377af83'),
				// 	carPlanDate: '', //this.$t('i18nn_99b88de52e382ca4'),
				// 	sendRecordDtoList: [
				// 		// {
				// 		// 	goodsSku: '', //this.$t('i18nn_74ed28f373ea14da'),
				// 		// 	toWhNo: '', //this.$t('i18nn_0f859b9b45732564'),
				// 		// 	fbaNo: '', //fba编号
				// 		// 	sendAcount: '', //this.$t('i18nn_2ec0f364e8bbaa4c'),
				// 		// 	remark: '' //this.$t('i18nn_15b3627faddccb1d')
				// 		// }
				// 	]
				// },
				// isShowSkuCheck: false,
				isSaveAddress: false,
				addressName: "",
				
				form: {
					"whNo": null, //this.$t('i18nn_c944a6686d996ab3'),
					// "inWhType": null, //this.$t('i18nn_643e08ba53f72f39'),
					"arriveWhWay": null, //this.$t('i18nn_1db6434a8c2ba647'),
					"isShowSku": '0', //this.$t('i18nn_696eca4149742942'),
					"arriveWhDate": null, //this.$t('i18nn_1f6b9a150926ba64'),
					// "inWhPallte": null, //this.$t('i18nn_33a85575edeaacaa'),
					"relationNo": null, //this.$t('i18nn_9168144190f66f5d'),
					
					"trOpType": "20", //this.$t('i18nn_17768c25d95d04ca'),
					
					"isFba": '1', //"是否FBA",
					"fbaAddrCode": null, //"FBA仓库编号",
					"country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
					"state": null, //"省/州",
					"city": null, //this.$t('i18nn_e05b5d049b64b040'),
					"postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
					"addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					"phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
					// "fileList": [{
					// 	"type": null, //"90",
					// 	"url": null, //"url",
					// 	"fileName": ""
					// }],
					"recoreList": [{
						// "inWhType": null, //this.$t('i18nn_643e08ba53f72f39'),
						"ctnMark": null, //this.$t('i18nn_8a43886ff356ed78'),
						"ctnCount": null, //this.$t('i18nn_53fa7ff1ea383b2f'),
						"goodsSku": null, //"箱内SKU",
						"ctnSkuCount": null, //this.$t('i18nn_85a10d3e5ea0e103'),
						"inWhPallte": null, //this.$t('i18nn_18c1c1a3ea76e9d3'),
						"skuTotal": null, //this.$t('i18nn_bad53577db0da2d3'),
						"ctnL": null, //this.$t('i18nn_70b5c883b227851b'),
						"ctnW": null, //this.$t('i18nn_0a389c3a5e3938d3'),
						"ctnH": null, //this.$t('i18nn_5c31dfdb8f4781ec'),
						"ctnWeight": null, //this.$t('i18nn_91263f8fa1a94da9'),
						"ctnUnit": null, //this.$t('i18nn_b6bf0a07fe26f74f')
						"remark": null
					}]
				},

				formRules: {
					sendNo: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					recevieName: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					whNo: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					recevieMobile: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					address1: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					carTmsType: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'change'
					}],
					postCode: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					country: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}], //this.$t('i18nn_b166e4e8fe9513fa'),
					province: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}], //"省/州",
					city: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}], //this.$t('i18nn_e05b5d049b64b040'),
					// address1: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('i18nn_8db45b615b72b7a8'),
					// carTmsType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('i18nn_5165f3ee9377af83'),
					carPlanDate: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}] //this.$t('i18nn_99b88de52e382ca4'),
				},
				loading_load: false,
				selectOption: {
					wh_size_unit: [],
					wh_transfer_Inwh_type: [],
					wh_transfer_arrive_way: [],
					biz_wh_in_tr_type:[],
				},

				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				isOnlyView: false,

				// loading: false,

				//是否可编辑
				// hasEdit:false,
				// loading_det: false,

				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// excelDataDto:[],

				// formBase: {
				// 	id: '', //"数据ID",
				// 	title: '', //this.$t('i18nn_7431e76029678ec7'),
				// 	orderType: '30', //this.$t('i18nn_f566f50ef680920c'),
				// 	contentStr: '', //this.$t('i18nn_3e358d140504ab46'),
				// 	attachments: [
				// 		// {
				// 			// id: '', //"数据ID",
				// 			// url: '', //"文件URL",
				// 			// fileName: '' //this.$t('i18nn_89221ad15d2ec113')
				// 		// }
				// 	]
				// },

				// formBaseRules: {
				// 	// title: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// contentStr: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// orderType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
				// },
				// loading_load: false,

				// selectOption: {
				// 	wh_work_order_type: []
				// }
			};
		},
		// watch: {
		// 	'$route.query': function(newVal, oldVal) {
		// 		console.log('$route.query', newVal);
		// 		// if(newVal){
		// 		// this.dialogFormVisible = true;
		// 		this.initData();
		// 		// }
		// 	}
		// },
		//创建时
		created() {
			// this.getPageData();
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_transfer_Inwh_type', 'wh_transfer_arrive_way','biz_wh_in_tr_type',
						'wh_size_unit'],
				(data)=>{
					this.selectOption.wh_size_unit = data['wh_size_unit'];
					this.selectOption.wh_transfer_Inwh_type = data['wh_transfer_Inwh_type'];
					this.selectOption.wh_transfer_arrive_way = data['wh_transfer_arrive_way'];
					this.selectOption.biz_wh_in_tr_type = data['biz_wh_in_tr_type'];
			});
		},
		methods: {
			initData() {
				console.log('created');
				this.$nextTick(() => {
					this.$refs.HotTableTransport.clear();
					this.changeIsShowSku(this.form.isShowSku);
				});
				// this.isOnlyView = false;
				if (this.$route.query.editId) {
					this.editId = this.$route.query.editId;
					//不允许编辑
					// this.hasEdit = false;
					this.getPageBaseData(this.editId);
					// this.isOnlyView = true;
					// this.fileRelationId = this.editId;
				}

				this.openUploadFile();
				
				this.$nextTick(() => {
					this.$refs.HotTableTransport.showColumn_TrOpTypeFast();
				});
				
				// this.FileUploadOpenTime = new Date().getTime();
				// else {
				// 	this.hasEdit = true;
				// }
			},
			goBack() {
				this.$router.push({
					name: 'TransportInWh'
				});
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			//新增
			addDet() {
				this.form.recoreList.push({
					"ctnMark": null, //this.$t('i18nn_8a43886ff356ed78'),
					"ctnCount": null, //this.$t('i18nn_53fa7ff1ea383b2f'),
					"goodsSku": null, //"箱内SKU",
					"ctnSkuCount": null, //this.$t('i18nn_85a10d3e5ea0e103'),
					"inWhPallte": null, //this.$t('i18nn_18c1c1a3ea76e9d3'),
					"skuTotal": null, //this.$t('i18nn_bad53577db0da2d3'),
					"ctnL": null, //this.$t('i18nn_70b5c883b227851b'),
					"ctnW": null, //this.$t('i18nn_0a389c3a5e3938d3'),
					"ctnH": null, //this.$t('i18nn_5c31dfdb8f4781ec'),
					"ctnWeight": null, //this.$t('i18nn_91263f8fa1a94da9'),
					"ctnUnit": null, //this.$t('i18nn_b6bf0a07fe26f74f')
					"remark": null,//备注
				});
			},
			// 删除
			delDet(event, row, index) {
				event.stopPropagation();
				this.form.recoreList.splice(index, 1);
			},
			// parsingSuccess(data){
			// 	this.formBase.sendDto = data;
			// },
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//提交(先提交附件，回调之后提交新增或者编辑方法)
			submitFormAction() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$nextTick(() => {
							this.$refs.whFileUploadInner.submitExcelFormAction();
						});
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},

			//初始化附件上传
			openUploadFile() {
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				this.fileRelationId = this.editId;
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				console.log("FileUploadSuccess", data);
				// this.initData();
				this.form.fileList = data;
				
				// return;
				this.submitForm('form');
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
				this.$forceUpdate();
			},
			//改变列隐藏-SKU
			changeIsShowSku(v) {
				console.log(v);
				if (v == '0') {
					this.$nextTick(() => {
						this.$refs.HotTableTransport.hideSkuColumn();
					});
				} else {
					this.$nextTick(() => {
						this.$refs.HotTableTransport.showSkuColumn();
					});
				}
			},
			
			//转运方式
			// changeTrOpType(v){
			// 	if('10'==this.form.trOpType){//快进快出-快速转运
			// 		this.$nextTick(() => {
			// 			this.$refs.HotTableTransport.hideColumn_TrOpTypeFast();
			// 		});
			// 	} else if('20'==this.form.trOpType){//分批转运
			// 		this.$nextTick(() => {
			// 			this.$refs.HotTableTransport.showColumn_TrOpTypeFast();
			// 		});
			// 	}
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//请求分页数据
			getPageBaseData(id) {
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferEditQuery + '/' + id, {})
					.then(({
						data
					}) => {
						console.log('分页，请求成功');
						console.log(data);
						this.loading = false;
						this.form = data.data;
						// this.getPageDetData(this.editId);
						this.$nextTick(() => {
							this.$refs.whNoSelect.init(this.form.whNo);
						});
						if (0 != data.data.status && 10 != data.data.status) {
							this.isOnlyView = true;
						}
						// this.isShowSkuCheck = this.form.isShowSku==1?true:false;
						// this.form.isShowSku = this.form.isShowSku==1?true:false;
						// this.$nextTick(() => {
						console.log('this.form.recoreList', this.form.recoreList);
						this.$refs.HotTableTransport.loadData(this.form.recoreList);
						// });
						this.changeIsShowSku(this.form.isShowSku)
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},
			//明细
			//请求分页数据
			// getPageDetData(id) {
			// 	// let _this = this;
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhTransferDetQuery + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			this.loading = false;
			// 			// this.form = data.data;
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },
			//打开选择数据
			openSelData(event, row, index) {
				event.stopPropagation();

				this.dialogSelVisible = true;
				this.openRowData = row;
				this.openRowIndex = index;
				// this.$nextTick(() => {
				//   this.$refs.hyUpLoadImg1.openFile();
				// });
			},
			//选择数据后回调
			selWhGoodsData(selRow) {
				this.dialogSelVisible = false;
				console.log('selRow', selRow);
				let row = this.openRowData;
				// row.putWhNo = selRow.putWhNo;
				// row.plPlaceNo = selRow.placeNo;
				row.goodsSku = selRow.goodsSku;
				// row.sendAcount = 0;
				// row.mySkuDto = selRow;

				this.$set(this.form.recoreList, this.openRowIndex, row);
			},
			
			//提交信息
			submitForm(formName) {
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.submitUpload();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });
				// let recoreList = this.$refs.HotTableTransport.getData();
				// console.log("recoreList",recoreList);
				// return;
				// console.log('formBase',this.formBase);
				console.log('form', this.form);
				let formData = Object.assign({}, this.form);
				// this.$nextTick(() => {
				formData.recoreList = this.$refs.HotTableTransport.getData();
				// });
				// formData.isShowSku = this.isShowSkuCheck?'1':'0';
				console.log('formData', formData);
				
				if (this.isSaveAddress && this.addressName) {
						this.saveAddress();
				}
				// formData.id = this.editId;
				// formData.whNo = this.form.whNo;
				// formData.sendDto = this.form;
				//浅拷贝、对象属性的合并
				// if (0 === this.dialogFormStatus) {
				// formData.id = null;
				// formData.userId = this.UserInfo.id;
				if (this.editId) {
					// formData.id = this.editId;
					this.postData(this.$urlConfig.WhTransferEdit, formData, () => {
						console.log('submitSuccess');
						// this.$emit('submitSuccess');
						this.goBack();
					});
				} else {
					this.postData(this.$urlConfig.WhTransferAdd, formData, () => {
						console.log('submitSuccess');
						// this.$emit('submitSuccess');
						this.goBack();
					});
				}
				// } else {
				// 	formData.userId = this.UserInfo.id;
				// 	this.postData(this.$urlConfig.WhWorkOrderAdd, formData);
				// }
			},
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	// console.log(this.$refs[formName]);

			// 	this.$nextTick(() => {
			// 		if (this.$refs[formName]) {
			// 			this.$refs[formName].resetFields();
			// 			// if ('form' === formName) {
			// 			// 	this[formName]['packRecordList'] = [];
			// 			// }
			// 		} else {
			// 			console.log('this.$refs[formName]', this.$refs[formName]);
			// 		}
			// 	});
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.HyElWhUpLoad.initData();
			// 			this.$refs.HyElWhUpLoad.clearFileList();
			// 		} catch (err) {
			// 			console.log(err);
			// 		}
			// 	});
			// 	this.fileList = [];
			// },
			//查询FBA地址
			searchFbaAddress() {
				console.log('searchFbaAddress');
				if ('' == this.form.fbaAddrCode) {
					this.$message.warning("请输入FBA仓库号");
					return;
				}
				this.form.country = "";
				this.form.state = "";
				this.form.city = "";
				this.form.postalCode = "";
				this.form.addr = "";
				this.form.phone = "";
				
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + this.form.fbaAddrCode, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'));
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.data) {
							// "country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
							// "state": null, //"省/州",
							// "city": null, //this.$t('i18nn_e05b5d049b64b040'),
							// "postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
							// "addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
							// "phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
							let addrData = data.data;
							this.form.country = addrData.country;
							this.form.state = addrData.state;
							this.form.city = addrData.city;
							this.form.postalCode = addrData.zipCode;
							this.form.addr = addrData.address;
							this.form.phone = addrData.phone;
			
						} else {
							// if (!data.msg) {
							// 	data.msg = this.$t('i18nn_d74d0bd89431d6d5');
							// }
							// this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.errorData'));
						this.loading = false;
						// this.$message.error('查询失败!');
					});
			},
			
			changeTransferAddress(data) {
				console.log('changeTransferAddress', data);
				// this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				this.addressName = data.name;
				this.form.addr = data.address;
				this.form.city = data.city;
				this.form.state = data.state;
				this.form.country = data.country;
				this.form.postalCode = data.zipCode;
				
				// "name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
				// "address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
				// "city": this.form.city, //this.$t('i18nn_e05b5d049b64b040'),
				// "state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
				// "zipCode": this.form.postalCode, //this.$t('i18nn_c4913ab43009b365'),
				// "country": this.form.country, //"US"
			},
			saveAddress() {
				this.$nextTick(() => {
					let id = this.$refs.transferAddressSel.getId();
					let address_formData = {
						"id": id?id:null, //"数据ID",
						"name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
						"address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
						"city": this.form.city, //this.$t('i18nn_e05b5d049b64b040'),
						"state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
						"zipCode": this.form.postalCode, //this.$t('i18nn_c4913ab43009b365'),
						"country": this.form.country, //"US"
						// phone: this.form.phone, //"phone"
					};
					this.$refs.transferAddressSel.addData(address_formData);
				})
			},

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loading = false;
					if (200 == data.code) {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_transfer_Inwh_type', 'wh_transfer_arrive_way','biz_wh_in_tr_type',
			// 			'wh_size_unit'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_transfer_Inwh_type = data.data['wh_transfer_Inwh_type'];
			// 				this.selectOption.wh_transfer_arrive_way = data.data['wh_transfer_arrive_way'];
			// 				this.selectOption.biz_wh_in_tr_type = data.data['biz_wh_in_tr_type'];
							
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.queryErrorFailed');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.queryRequestFailed'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
